import React from 'react';
import Grid from '@material-ui/core/Grid';
import { NavLink } from 'react-router-dom';
import ArrowIcon from '@material-ui/icons/ArrowRight';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import HeartIcon from '@material-ui/icons/Favorite';

import logo from '../assets/goodbod_logo.png';
import '../styles/footer.scss';

const Footer = ({ history }) => {
  return (
    <div className="main-footer-area">
      <Grid
        container
        justify="center"
        spacing={5}
        className="footer-grid-container"
      >
        <Grid item xs={6} sm={4} md={4} lg={4} xl={4} className="no-gutters">
          <div className="contact-info">
            <img
              src={logo}
              alt="GoodBod Logo"
              onClick={() => history.push('/home')}
              className="logo"
            />
            <h4>(+91)-77988 89943</h4>
            <span>shalini.singh@goodbod.in</span>
            <span>
              Kaplansgatan 6 <br />
              151 72 Södertälje
            </span>
          </div>
        </Grid>
        <Grid item xs={6} sm={4} md={4} lg={4} xl={4} className="no-gutters">
          <div className="useful-links">
            <h5 className="widget-title">Useful Links</h5>
            <ul>
              <li>
                <ArrowIcon id="icon" />
                <NavLink key={'home'} className={'menu'} to={'/'} exact={true}>
                  Home
                </NavLink>
              </li>
              <li>
                <ArrowIcon id="icon" />
                <NavLink
                  key={'workouts'}
                  className={'menu'}
                  to={'/workout'}
                  exact={true}
                >
                  Workouts
                </NavLink>
              </li>
              <li>
                <ArrowIcon id="icon" />
                <NavLink
                  key={'pricing'}
                  className={'menu'}
                  to={'/plans'}
                  exact={true}
                >
                  Pricing
                </NavLink>
              </li>
              <li>
                <ArrowIcon id="icon" />
                <NavLink
                  key={'trainer-join'}
                  className={'menu'}
                  to={'trainer-join'}
                  exact={true}
                >
                  Become a Trainer
                </NavLink>
              </li>
              <li>
                <ArrowIcon id="icon" />
                <NavLink
                  key={'business'}
                  className={'menu'}
                  to={'business'}
                  exact={true}
                >
                  GoodBod for Business
                </NavLink>
              </li>
              <li>
                <ArrowIcon id="icon" />
                <NavLink
                  key={'about'}
                  className={'menu'}
                  to={'about'}
                  exact={true}
                >
                  About
                </NavLink>
              </li>
            </ul>
          </div>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4} className="no-gutters">
          <div className="social-media">
            <h5 className="widget-title">Follow us</h5>
            <div className="social-info">
              <a
                href="https://www.facebook.com/#"
                rel="noopener noreferrer"
                target="_blank"
              >
                <FacebookIcon
                  id="footer-social-media-icon"
                  className="facebook-icon"
                />
              </a>
              <a
                href="https://www.instagram.com/#?r=nametag"
                rel="noopener noreferrer"
                target="_blank"
              >
                <InstagramIcon
                  id="footer-social-media-icon"
                  className="instagram-icon"
                />
              </a>
              <a
                href="https://www.instagram.com/#?r=nametag"
                rel="noopener noreferrer"
                target="_blank"
              >
                <TwitterIcon
                  id="footer-social-media-icon"
                  className="twitter-icon"
                />
              </a>
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="no-gutters"
        >
          <div className="copywrite-content">
            <p>
              Copyright ©
              <script
                type="text/javascript"
                async=""
                src="https://www.google-analytics.com/analytics.js"
              ></script>
              <script type="text/javascript">
                document.write(new Date().getFullYear());
              </script>
              2020 All rights reserved to GoodBod | This template is made with{' '}
              <HeartIcon id="icon" /> by{' '}
              <a
                href="https://www.devarena.in"
                target="_blank"
                rel="noopener noreferrer"
              >
                DevArena Solutions
              </a>
            </p>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export default Footer;

import {
  EXECUTION_STATUS,
  GET_USER,
  GET_USER_ACTIVE_SUBSCRIPTION,
  GET_USERS,
} from './actionTypes';
import firebase from 'firebase/app';
import 'firebase/firestore';

import { isUserAdmin } from '../utils/menu';
import { isCharDigit } from '../utils';

const { STARTED, FINISHED, ERROR } = EXECUTION_STATUS;

export function getAllUsers(user) {
  return (dispatch) => {
    dispatch({
      type: GET_USERS + STARTED,
      payload: {
        users: [],
      },
    });
    let users = [];
    firebase
      .firestore()
      .collection('users')
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.size === 0) {
          dispatch({
            type: GET_USERS + FINISHED,
            payload: {
              users: [],
            },
          });
        } else {
          let index = 1;
          querySnapshot.forEach((doc) => {
            const user = doc.data();
            const userData = {};
            userData.id = doc.id;
            userData.displayName = user.displayName;
            userData.email = user.email;

            if (isCharDigit(user.createdAt.toString().charAt(0))) {
              userData.createdAt = new Date(
                parseInt(user.createdAt)
              ).toUTCString();
            } else {
              userData.createdAt = new Date(user.createdAt).toUTCString();
            }

            if (isCharDigit(user.lastLoginAt.toString().charAt(0))) {
              userData.lastLoginAt = new Date(
                parseInt(user.lastLoginAt)
              ).toUTCString();
            } else {
              userData.lastLoginAt = new Date(user.lastLoginAt).toUTCString();
            }
            userData.providerId = user.providerData[0].providerId;
            users.push(userData);
            if (index === querySnapshot.size) {
              populateUsersSubscription(users, dispatch);
            }
            index++;
          });
        }
      })
      .catch((error) => {
        console.error(
          'error while getting the all users with email id in create user :: ',
          error
        );
        dispatch({
          type: GET_USERS + ERROR,
        });
      });
  };
}

function populateUsersSubscription(users, dispatch) {
  users.forEach((user) => {
    firebase
      .firestore()
      .collection('subscriptions')
      .where('email', '==', user.email)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.size > 0) {
          let index = 1;
          querySnapshot.forEach((doc) => {
            const subscription = doc.data();
            subscription.id = doc.id;
            if (subscription.expiry) {
              subscription.expiry = new Date(subscription.expiry);
            }
            user.subscription = subscription;

            if (index === querySnapshot.size) {
              dispatch({
                type: GET_USERS + FINISHED,
                payload: {
                  users,
                },
              });
            }
            index++;
          });
        } else {
          dispatch({
            type: GET_USERS + FINISHED,
            payload: {
              users,
            },
          });
        }
      })
      .catch((error) => {
        console.error(
          'Error while FETCHING the ACTIVE USER subscription for user list ::: ',
          error
        );
        dispatch({
          type: GET_USER_ACTIVE_SUBSCRIPTION + ERROR,
        });
      });
  });
}

function populateUserSubscription(user, dispatch) {
  firebase
    .firestore()
    .collection('subscriptions')
    .where('email', '==', user.email)
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.size > 0) {
        let index = 1;
        querySnapshot.forEach((doc) => {
          const subscription = doc.data();
          subscription.id = doc.id;
          if (subscription.expiry) {
            subscription.expiry = new Date(subscription.expiry);
          }
          user.subscription = subscription;
          if (index === querySnapshot.size) {
            updateUserStore(dispatch, user);
          }
          index++;
        });
      } else {
        updateUserStore(dispatch, user);
      }
    })
    .catch((error) => {
      console.error(
        'Error while FETCHING the ACTIVE USER subscription for user list ::: ',
        error
      );
      dispatch({
        type: GET_USER_ACTIVE_SUBSCRIPTION + ERROR,
      });
    });
}

export function createOrUpdateUser(user) {
  return (dispatch) => {
    dispatch({
      type: GET_USER + STARTED,
      payload: {
        user: {},
      },
    });

    firebase
      .firestore()
      .collection('users')
      .where('email', '==', user.email)
      .get()
      .then((querySnapshot) => {
        const parsedUser = JSON.parse(JSON.stringify(user));
        setUserRole(parsedUser);
        parsedUser.lastActiveAt = new Date().toUTCString();

        if (isCharDigit(parsedUser.createdAt.toString().charAt(0))) {
          parsedUser.createdAt = new Date(
            parseInt(parsedUser.createdAt)
          ).toUTCString();
        } else {
          parsedUser.createdAt = new Date(parsedUser.createdAt).toUTCString();
        }

        if (isCharDigit(parsedUser.lastLoginAt.toString().charAt(0))) {
          parsedUser.lastLoginAt = new Date(
            parseInt(parsedUser.lastLoginAt)
          ).toUTCString();
        } else {
          parsedUser.lastLoginAt = new Date(
            parsedUser.lastLoginAt
          ).toUTCString();
        }

        populateUserSubscription(
          JSON.parse(JSON.stringify(parsedUser)),
          dispatch
        );

        firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .set(parsedUser)
          .then(() => {
            console.log('USER CREATED SUCCESSFULLY !!!');
          })
          .catch((error) =>
            console.error('ERROR WHILE CREATING USER ::: ', error)
          );
      })
      .catch((error) => {
        console.error(
          'error while getting the user with email id in create user :: ',
          error
        );
        logoutUser();
      });
  };
}

function setUserRole(user) {
  const { email } = user;
  if (isUserAdmin(email)) {
    user.role = 'admin';
  } else {
    user.role = 'user';
  }
}

export function updateUserStore(dispatch, userData) {
  dispatch({
    type: GET_USER + FINISHED,
    payload: {
      user: userData,
    },
  });
}

export function logoutUser() {
  return (dispatch) => updateUserStore(dispatch, null);
}

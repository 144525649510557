export const EXECUTION_STATUS = {
  STARTED: '_STARTED',
  FINISHED: '_FINISHED',
  ERROR: '_ERROR',
};

export const CREATE_WORKOUT = 'CREATE_WORKOUT';
export const UPDATE_WORKOUT = 'UPDATE_WORKOUT';
export const DELETE_WORKOUT = 'DELETE_WORKOUT';
export const GET_WORKOUTS = 'GET_WORKOUTS';
export const CLEAR_WORKOUT_STATE = 'CLEAR_WORKOUT_STATE';

export const GET_WORKOUT_OVERVIEW = 'GET_WORKOUT_OVERVIEW';
export const UPDATE_WORKOUT_OVERVIEW = 'UPDATE_WORKOUT_OVERVIEW';
export const CLEAR_WORKOUT_OVERVIEW_STATE = 'CLEAR_WORKOUT_OVERVIEW_STATE';

export const GET_WORKOUT_VIDEO = 'GET_WORKOUT_VIDEO';
export const CREATE_WORKOUT_VIDEO = 'CREATE_WORKOUT_VIDEO';
export const UPDATE_WORKOUT_VIDEO = 'UPDATE_WORKOUT_VIDEO';
export const DELETE_WORKOUT_VIDEO = 'DELETE_WORKOUT_VIDEO';
export const CLEAR_WORKOUT_VIDEO_STATE = 'CLEAR_WORKOUT_VIDEO_STATE';

export const GET_WORKOUT_MATERIALS = 'GET_WORKOUT_MATERIALS';
export const CREATE_WORKOUT_MATERIAL = 'CREATE_WORKOUT_MATERIAL';
export const UPDATE_WORKOUT_MATERIAL = 'UPDATE_WORKOUT_MATERIAL';
export const DELETE_WORKOUT_MATERIAL = 'DELETE_WORKOUT_MATERIAL';
export const CLEAR_WORKOUT_MATERIAL_STATE = 'CLEAR_WORKOUT_MATERIAL_STATE';

/*USERS ACTION STARTS*/
export const GET_USERS = 'GET_USERS';
export const GET_USER = 'GET_USER';
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USERS = 'UPDATE_USERS';
/*USERS ACTION ENDS*/

/*PLANS ACTION STARTS*/
export const GET_PLANS = 'GET_PLANS';
/*PLANS ACTION ENDS*/

/*SUBSCRIPTION ACTION STARTS*/
export const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION';
export const GET_USER_ACTIVE_SUBSCRIPTION = 'GET_USER_ACTIVE_SUBSCRIPTION';
export const CLEAR_SUBSCRIPTION_STATE = 'CLEAR_SUBSCRIPTION_STATE';
/*SUBSCRIPTION ACTION ENDS*/

/*DIET ACTION STARTS*/
export const CREATE_DIET = 'CREATE_DIET';
export const UPDATE_DIET = 'UPDATE_DIET';
export const DELETE_DIET = 'DELETE_DIET';
export const GET_DIETS = 'GET_DIETS';
export const GET_DETAIL_DIET = 'GET_DETAIL_DIET';
export const CREATE_DETAIL_DIET = 'CREATE_DETAIL_DIET';
export const UPDATE_DETAIL_DIET = 'UPDATE_DETAIL_DIET';
export const DELETE_DETAIL_DIET = 'DELETE_DETAIL_DIET';
export const CLEAR_DIET_STATE = 'CLEAR_DIET_STATE';
export const CLEAR_DETAIL_DIET_STATE = 'CLEAR_DETAIL_DIET_STATE';
/*DIET ACTION ENDS*/

/*BLOG ACTION STARTS*/
export const CREATE_BLOG = 'CREATE_BLOG';
export const UPDATE_BLOG = 'UPDATE_BLOG';
export const DELETE_BLOG = 'DELETE_BLOG';
export const GET_BLOG = 'GET_BLOG';
export const GET_DETAIL_BLOG = 'GET_DETAIL_BLOG';
export const CREATE_DETAIL_BLOG = 'CREATE_DETAIL_BLOG';
export const UPDATE_DETAIL_BLOG = 'UPDATE_DETAIL_BLOG';
export const DELETE_DETAIL_BLOG = 'DELETE_DETAIL_BLOG';
export const CLEAR_BLOG_STATE = 'CLEAR_BLOG_STATE';
export const CLEAR_DETAIL_BLOG_STATE = 'CLEAR_DETAIL_BLOG_STATE';
/*BLOG ACTION ENDS*/

import {
  CLEAR_DIET_STATE,
  CREATE_DIET,
  DELETE_DIET,
  EXECUTION_STATUS,
  GET_DIETS,
  UPDATE_DIET,
} from '../actions/actionTypes';

const INITIAL_STATE = {
  diets: [],
  id: '',
  name: '',
  time: '',
  duration: '',
  trainerName: '',
  description: '',
  dietsImage: null,
  isLoaded: false,
  isCreating: false,
  isCreated: false,
  isDeleting: false,
  isUpdating: false,
  isUpdated: false,
  isLoading: false,
};

export default function (state = INITIAL_STATE, action) {
  let { payload } = action;
  const { STARTED, FINISHED, ERROR } = EXECUTION_STATUS;
  switch (action.type) {
    ////////////////////////////////////////////////////////////////////////////////////////

    case GET_DIETS + STARTED:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    case GET_DIETS + FINISHED:
      return {
        ...state,
        diets: payload,
        isLoading: false,
        isLoaded: true,
      };
    case GET_DIETS + ERROR:
      return {
        ...state,
        isLoading: false,
        error: 'Error while loading diets',
      };

    ////////////////////////////////////////////////////////////////////////////////////////

    case CREATE_DIET + STARTED:
      return {
        ...state,
        isCreating: true,
        isCreated: false,
      };
    case CREATE_DIET + FINISHED:
      state.diets.unshift(payload);
      return {
        ...state,
        isCreating: false,
        isCreated: true,
      };
    case CREATE_DIET + ERROR:
      console.log('IN CREATE_DIET_ERROR :: ');
      return {
        ...state,
        isCreating: false,
        error: 'Error while creating diets',
      };

    ////////////////////////////////////////////////////////////////////////////////////////

    case UPDATE_DIET + STARTED:
      return {
        ...state,
        isUpdating: true,
        isUpdated: false,
      };
    case UPDATE_DIET + FINISHED:
      state.diets.map((diet, index, diets) =>
        updateDiet(diet, index, diets, payload)
      );
      return {
        ...state,
        isUpdating: false,
        isUpdated: true,
      };
    case UPDATE_DIET + ERROR:
      console.log('IN UPDATE_DIET_ERROR :::');
      return {
        ...state,
        isUpdating: false,
        error: 'Error while updating diets',
      };

    ////////////////////////////////////////////////////////////////////////////////////////

    case DELETE_DIET + STARTED:
      return {
        ...state,
        isDeleting: true,
      };
    case DELETE_DIET + FINISHED:
      const dietsToDelete = payload;
      const dietsAfterDelete = state.diets.filter(
        (diets) => diets.id !== dietsToDelete
      );
      return {
        ...state,
        diets: dietsAfterDelete,
        isDeleting: false,
      };
    case DELETE_DIET + ERROR:
      return {
        ...state,
        isDeleting: true,
        error: 'Error while deleting diets',
      };

    ////////////////////////////////////////////////////////////////////////////////////////

    case CLEAR_DIET_STATE + FINISHED:
      return {
        ...state,
        isCreating: false,
        isCreated: false,
        isLoading: false,
        isUpdated: false,
        isUpdating: false,
      };
    default:
      return state;
  }
}

function updateDiet(diet, index, diets, updatedDiet) {
  if (diet.id === updatedDiet.id) {
    diet = updatedDiet;
    diets[index] = diet;
  }
  //return diets;
}

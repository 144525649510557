import {
  CLEAR_WORKOUT_STATE,
  CREATE_WORKOUT,
  DELETE_WORKOUT,
  EXECUTION_STATUS,
  GET_PLANS, UPDATE_WORKOUT
} from '../actions/actionTypes';

const INITIAL_STATE = {
  plans: [],
  isLoading: false
};

export default function(state = INITIAL_STATE, action) {
  let {payload} = action;
  const {STARTED, FINISHED, ERROR} = EXECUTION_STATUS;
  switch (action.type) {
  
    ////////////////////////////////////////////////////////////////////////////////////////
  
    case GET_PLANS + STARTED:
      return {
        ...state,
        isLoading: true,
      };
    case GET_PLANS + FINISHED:
      return {
        ...state,
        plans: payload,
        isLoading: false,
      };
    case GET_PLANS + ERROR:
      return {
        ...state,
        isLoading: false,
        error: 'Error while loading plans'
      };
      
    ////////////////////////////////////////////////////////////////////////////////////////
  
    case CLEAR_WORKOUT_STATE + FINISHED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}

function updateWorkout(workout, index, workouts, updatedWorkout) {
  if (workout.id === updatedWorkout.id) {
    workout = updatedWorkout;
    workouts[index] = workout;
  }
  return workout;
}
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import AccountCircle from '@material-ui/icons/AccountCircle';
import PasswordIcon from '@material-ui/icons/Lock';
import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { GET_USER, EXECUTION_STATUS } from '../actions/actionTypes';
import { withFirebase } from '../utils/Firebase';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

import '../styles/login.scss';

const { STARTED, FINISHED, ERROR } = EXECUTION_STATUS;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Login = ({ closeModal, firebase, showSignUpModal }) => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [open, setOpen] = useState(false);
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [error, setError] = useState(undefined);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleGoogleLogin = (e) => {
    e.preventDefault();
    firebase
      .loginWithGoogle()
      .then(({ user }) => {
        console.log('Login with google was successful :::: ');
      })
      .catch(function (error) {
        //alert(error); // or show toast
        console.log('Google Sign Error in Login page ::: ', error);
      });
  };

  const handleEmailAndPasswordLogin = () => {
    dispatch({
      type: GET_USER + STARTED,
    });
    setIsSigningIn(true);
    firebase.firebaseAuth
      .signInWithEmailAndPassword(email, password)
      .then(({ user }) => {
        dispatch({
          type: GET_USER + FINISHED,
          payload: user,
        });
        setIsSigningIn(false);
        console.log('Login with email and password was successful :::: ');
        closeModal();
      })
      .catch(function (error) {
        //alert(error); // or show toast
        setError(error.message);
        setOpen(true);
        console.log(
          'signInWithEmailAndPassword Sign Error in Login page ::: ',
          error
        );
        setIsSigningIn(false);
        dispatch({
          type: GET_USER + ERROR,
        });
      });
  };

  const handleForgotPassword = () => {
    console.log('IN HANDLE FORGOT PASSWORD');
    firebase.firebaseAuth
      .sendPasswordResetEmail('brahmdev.pandey@devarena.in')
      .then(function () {
        console.log('EMAIL SENT');
      })
      .catch(function (error) {
        console.log('Email not sent :: ', error);
      });
  };

  return (
    <div className="login-page">
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {error ? error : 'Problem while signing in, contact Administrator.'}
        </Alert>
      </Snackbar>
      <Paper className="login-content-container">
        <CloseIcon className="close-icon" onClick={() => closeModal()} />
        <h1 className="title">Sign In With</h1>

        <Grid container justify="center" spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControl fullWidth>
              <InputLabel htmlFor="input-with-icon-adornment" className="label">
                Email
              </InputLabel>
              <Input
                id="email"
                fullWidth
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <AccountCircle className="icon" />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControl fullWidth>
              <InputLabel htmlFor="input-with-icon-adornment" className="label">
                Password
              </InputLabel>
              <Input
                id="password"
                fullWidth
                value={password}
                type="password"
                required
                onChange={(e) => setPassword(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <PasswordIcon className="icon" />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>

          {/* <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            onClick={handleForgotPassword}
          >
            <a>Forgot Password</a>
          </Grid> */}

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="action-buttons"
          >
            <Button
              variant="contained"
              className="login-button"
              disabled={isSigningIn}
              color="primary"
              onClick={() => handleEmailAndPasswordLogin()}
            >
              Sign In
            </Button>
            {isSigningIn && (
              <CircularProgress
                className="loader"
                size={24}
                className="btn-progress"
              />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="separator"
          >
            Or
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="action-buttons"
          >
            <Button
              variant="contained"
              className="google-login-button"
              color="primary"
              onClick={(e) => handleGoogleLogin(e)}
              startIcon={
                <img
                  className="google-icon"
                  src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
                />
              }
            >
              Google
            </Button>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="action-buttons signup-container"
            onClick={showSignUpModal}
          >
            <span>Create your Account</span>
            <ArrowForwardIcon className="icon" />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};
export default withFirebase(Login);

import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FullNameIcon from '@material-ui/icons/Keyboard';
import AccountCircle from '@material-ui/icons/AccountCircle';
import PasswordIcon from '@material-ui/icons/Lock';
import CloseIcon from '@material-ui/icons/Close';
import { withFirebase } from '../utils/Firebase';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { validateEmail } from '../utils';

import '../styles/login.scss';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SignUp = ({ closeModal, firebase }) => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [isSigningUp, setIsSigningUp] = useState(false);

  console.log('PROPS :: ', firebase);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleEmailAndPasswordSignUp = () => {
    console.log(
      'password :: ',
      password,
      ' confirmPassword :: ',
      confirmPassword
    );
    if (!validateEmail(email)) {
      setMessage('Invalid email format!');
      setOpen(true);
    } else if (password !== confirmPassword) {
      setMessage('password and confirm password do not match!');
      setOpen(true);
    } else {
      setIsSigningUp(true);
      firebase.firebaseAuth
        .createUserWithEmailAndPassword(email, password)
        .then(async ({ user }) => {
          if (user) {
            console.log('DisplayName would be :: ', fullName);
            await user.updateProfile({
              displayName: fullName,
            });
          }
          console.log('SignUp with email and password was successful :::: ');
          setIsSigningUp(false);
          closeModal();
        })
        .catch(function (error) {
          //alert(error); // or show toast
          setMessage(error.message);
          setOpen(true);
          setIsSigningUp(false);
          console.log(
            'signInWithEmailAndPassword Sign Error in Login page ::: ',
            error
          );
        });
    }
  };

  return (
    <div className="login-page">
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {message}
        </Alert>
      </Snackbar>
      <Paper className="login-content-container">
        <CloseIcon className="close-icon" onClick={() => closeModal()} />
        <h1 className="title">Sign Up</h1>

        <Grid container justify="center" spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControl fullWidth>
              <InputLabel htmlFor="input-with-icon-adornment" className="label">
                Full Name
              </InputLabel>
              <Input
                id="fullName"
                fullWidth
                value={fullName}
                required
                onChange={(e) => setFullName(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <FullNameIcon className="icon" />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControl fullWidth>
              <InputLabel htmlFor="input-with-icon-adornment" className="label">
                Email
              </InputLabel>
              <Input
                id="email"
                fullWidth
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <AccountCircle className="icon" />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControl fullWidth>
              <InputLabel htmlFor="input-with-icon-adornment" className="label">
                Password
              </InputLabel>
              <Input
                id="password"
                fullWidth
                value={password}
                type="password"
                required
                onChange={(e) => setPassword(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <PasswordIcon className="icon" />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControl fullWidth>
              <InputLabel htmlFor="input-with-icon-adornment" className="label">
                Confirm Password
              </InputLabel>
              <Input
                id="confirmPassword"
                fullWidth
                value={confirmPassword}
                type="password"
                required
                onChange={(e) => setConfirmPassword(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <PasswordIcon className="icon" />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="action-buttons"
          >
            <Button
              variant="contained"
              className="login-button"
              color="primary"
              disabled={isSigningUp}
              onClick={() => handleEmailAndPasswordSignUp()}
            >
              Sign Up
            </Button>
            {isSigningUp && (
              <CircularProgress
                className="loader"
                size={24}
                className="btn-progress"
              />
            )}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};
export default withFirebase(SignUp);

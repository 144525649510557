export const TOP_HEADER_MENU = [
  {
    name: 'Become a Trainer',
    link: '/trainer-join',
    activeLinks: ['/trainer-join'],
  },
  {
    name: 'Goodbod for Business',
    link: '/business',
    activeLinks: ['/business'],
  },
  {
    name: 'About',
    link: '/about',
  },
  {
    name: 'Help',
    link: '/help',
  },
];

const USER_MENU = [
  {
    name: 'Home',
    link: '/home',
    activeLinks: ['/home'],
  },
  {
    name: 'Programs',
    link: '/workout',
    activeLinks: [
      '/workout',
      '/add-workout',
      '/detail-workout/',
      '/edit-workout-overview/',
      '/add-workout-video/',
      '/add-workout-material/',
    ],
  },
  {
    name: 'Subscriptions',
    link: '/plans',
    activeLinks: ['/plans', '/payment/'],
  },
  {
    name: 'Nutrition',
    link: '/diet',
    activeLinks: ['/diet', '/add-diet', '/detail-diet/', '/add-detail-diet/'],
  },
  {
    name: 'Blog',
    link: '/blog',
    activeLinks: ['/blog', '/add-blog', '/detail-blog/', '/update-blog-data/'],
  },
];

const ADMIN_MENU = [
  {
    name: 'Admin',
    link: '/admin',
    activeLinks: ['/admin'],
  },
];

const ADMIN_EMAILS = [
  'pandey.dev25@gmail.com',
  'shalini6crore@gmail.com',
  'tiwarismahesh@gmail.com',
  'rhlsingh432@gmail.com',
  'vijay.g.gehlot@gmail.com',
];

export const getMenu = (role) => {
  if (role === 'admin') {
    return USER_MENU.concat(ADMIN_MENU);
  } else {
    return USER_MENU;
  }
};

export const isUserAdmin = (email) => {
  return ADMIN_EMAILS.includes(email);
};

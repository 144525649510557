import {
  CREATE_USER,
  EXECUTION_STATUS,
  GET_USER,
  GET_USERS,
  UPDATE_USERS,
} from '../actions/actionTypes';

const INITIAL_STATE = {
  user: null,
  users: [],
  isUserLoading: false,
  isUsersLoading: false,
};

export default function (state = INITIAL_STATE, action) {
  let { payload } = action;
  const { STARTED, FINISHED, ERROR } = EXECUTION_STATUS;

  switch (action.type) {
    case CREATE_USER:
      const { user } = payload;
      let isUserLoading = false;
      return { ...state, user, isUserLoading };

    ////////////////////////////////////////////////////////////////////////////////////////
    case GET_USERS + STARTED:
      return {
        ...state,
        isUsersLoading: true,
      };
    case GET_USERS + FINISHED:
      return {
        ...state,
        users: payload.users,
        isUsersLoading: false,
      };
    case GET_USERS + ERROR:
      console.log('IN GET_USERS_ERROR :::');
      return {
        ...state,
        isUsersLoading: false,
        error: 'Error while getting all users',
      };

    ////////////////////////////////////////////////////////////////////////////////////////
    case GET_USER + STARTED:
      return {
        ...state,
        isUserLoading: true,
      };
    case GET_USER + FINISHED:
      return {
        ...state,
        user: payload.user,
        isUserLoading: false,
      };
    case GET_USER + ERROR:
      console.log('IN GET_USER_ERROR :::');
      return {
        ...state,
        isUserLoading: false,
        error: 'Error while getting current user',
      };

    default:
      return state;
  }
}

import {
  CLEAR_BLOG_STATE,
  CREATE_BLOG,
  DELETE_BLOG,
  EXECUTION_STATUS,
  GET_BLOG,
  GET_DETAIL_BLOG,
  UPDATE_BLOG,
  UPDATE_DETAIL_BLOG,
} from '../actions/actionTypes';

const INITIAL_STATE = {
  blogs: [],
  detailBlogData: {},
  id: '',
  name: '',
  time: '',
  duration: '',
  trainerName: '',
  description: '',
  imagePath: null,
  isCreating: false,
  isCreated: false,
  isDeleting: false,
  isUpdating: false,
  isUpdated: false,
  isLoading: false,
  isLoaded: false,
};

export default function (state = INITIAL_STATE, action) {
  let { payload } = action;
  const { STARTED, FINISHED, ERROR } = EXECUTION_STATUS;
  switch (action.type) {
    ////////////////////////////////////////////////////////////////////////////////////////

    case GET_BLOG + STARTED:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    case GET_BLOG + FINISHED:
      return {
        ...state,
        blogs: payload,
        isLoading: false,
        isLoaded: true,
      };
    case GET_BLOG + ERROR:
      return {
        ...state,
        isLoading: false,
        error: 'Error while loading blogs',
      };

    ////////////////////////////////////////////////////////////////////////////////////////

    case GET_DETAIL_BLOG + STARTED:
      return {
        ...state,
        isLoading: true,
      };
    case GET_DETAIL_BLOG + FINISHED:
      return {
        ...state,
        detailBlogData: payload,
        isLoading: false,
      };
    case GET_DETAIL_BLOG + ERROR:
      return {
        ...state,
        isLoading: false,
        error: 'Error while loading detail blog data',
      };

    ////////////////////////////////////////////////////////////////////////////////////////

    case CREATE_BLOG + STARTED:
      return {
        ...state,
        isCreating: true,
        isCreated: false,
      };
    case CREATE_BLOG + FINISHED:
      state.blogs.unshift(payload);
      return {
        ...state,
        isCreating: false,
        isCreated: true,
      };
    case CREATE_BLOG + ERROR:
      console.log('IN CREATE_BLOG_ERROR :: ');
      return {
        ...state,
        isCreating: false,
        isCreated: false,
        error: 'Error while creating blogs',
      };

    ////////////////////////////////////////////////////////////////////////////////////////

    case UPDATE_BLOG + STARTED:
      return {
        ...state,
        isUpdating: true,
        isUpdated: false,
      };
    case UPDATE_BLOG + FINISHED:
      state.blogs.map((blog, index, blogs) =>
        updateBlog(blog, index, blogs, payload)
      );
      return {
        ...state,
        isUpdating: false,
        isUpdated: true,
      };
    case UPDATE_BLOG + ERROR:
      console.log('IN UPDATE_BLOG_ERROR :::');
      return {
        ...state,
        isUpdating: false,
        isUpdated: false,
        error: 'Error while updating blogs',
      };

    ////////////////////////////////////////////////////////////////////////////////////////

    case UPDATE_DETAIL_BLOG + STARTED:
      return {
        ...state,
        isUpdating: true,
        isUpdated: false,
      };
    case UPDATE_DETAIL_BLOG + FINISHED:
      return {
        ...state,
        detailBlogData: payload,
        isUpdating: false,
        isUpdated: true,
      };
    case UPDATE_DETAIL_BLOG + ERROR:
      console.error('IN UPDATE_DETAIL_BLOG_ERROR :::');
      return {
        ...state,
        isUpdating: false,
        error: 'Error while updating blogs',
      };

    ////////////////////////////////////////////////////////////////////////////////////////

    case DELETE_BLOG + STARTED:
      return {
        ...state,
        isDeleting: true,
      };
    case DELETE_BLOG + FINISHED:
      const blogsToDelete = payload;
      const blogsAfterDelete = state.blogs.filter(
        (blogs) => blogs.id !== blogsToDelete
      );
      return {
        ...state,
        blogs: blogsAfterDelete,
        isDeleting: false,
      };
    case DELETE_BLOG + ERROR:
      return {
        ...state,
        isDeleting: true,
        error: 'Error while deleting blogs',
      };

    ////////////////////////////////////////////////////////////////////////////////////////

    case CLEAR_BLOG_STATE + FINISHED:
      return {
        ...state,
        isCreating: false,
        isCreated: false,
        isLoading: false,
        isUpdated: false,
        isUpdating: false,
      };
    default:
      return state;
  }
}

function updateBlog(blog, index, blogs, updatedBlog) {
  if (blog.id === updatedBlog.id) {
    blog = updatedBlog;
    blogs[index] = blog;
  }
  //return blogs;
}

import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  Avatar,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  withStyles,
} from '@material-ui/core';
import { ExitToApp, Menu as MenuIcon } from '@material-ui/icons';
import { logoutUser, createOrUpdateUser } from '../actions/usersAction';
import { withFirebase } from '../utils/Firebase';
import { getMenu, TOP_HEADER_MENU } from '../utils/menu';
import { NavLink } from 'react-router-dom';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Login from '../screens/Login';
import SignUp from '../screens/SignUp';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import Drawer from '@material-ui/core/Drawer';

import logo from '../assets/goodbod_logo.png';
import '../styles/header.scss';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    marginTop: '10px',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const Header = (props) => {
  const dispatch = useDispatch();

  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [isLoginModalOpen, setLoginModalOpen] = React.useState(false);
  const [isSignUpModalOpen, setSignUpModalOpen] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const { user, isUserLoading } = useSelector((state) => state.user);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 100 && !scrolled) {
      setScrolled(true);
    } else if (offset < 100 && scrolled) {
      setScrolled(false);
    }
  };

  window.addEventListener('scroll', handleScroll);

  useEffect(() => {
    if (user === null) {
      handleFirebaseAuth();
    }
  }, [user]);

  const closeLogin = () => {
    setLoginModalOpen(false);
    setSignUpModalOpen(false);
  };

  const showSignUpModal = () => {
    setLoginModalOpen(false);
    setSignUpModalOpen(true);
  };

  const loginModalBody = (
    <div className="paper">
      <div className="modal-content">
        <IconButton aria-label="close" onClick={closeLogin}>
          <CloseIcon className="close-btn" />
        </IconButton>
        <Login
          closeModal={() => closeLogin()}
          showSignUpModal={() => showSignUpModal()}
        />
      </div>
    </div>
  );

  const signUpModalBody = (
    <div className="paper">
      <div className="modal-content">
        <IconButton aria-label="close" onClick={closeLogin}>
          <CloseIcon className="close-btn" />
        </IconButton>
        <SignUp closeModal={() => closeLogin()} />
      </div>
    </div>
  );

  const renderLoginModal = () => {
    return (
      <Modal
        open={isLoginModalOpen}
        onClose={closeLogin}
        className="modal"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        {loginModalBody}
      </Modal>
    );
  };

  const renderSignUpModal = () => {
    return (
      <Modal
        open={isSignUpModalOpen}
        onClose={closeLogin}
        className="modal"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        {signUpModalBody}
      </Modal>
    );
  };

  const makeUserLoggedIn = (user) => {
    if (user) {
      const {
        metadata: { creationTime, lastSignInTime },
      } = user;

      if (
        new Date(creationTime).getTime() === new Date(lastSignInTime).getTime()
      ) {
        user = JSON.parse(JSON.stringify(user));
        user.createdAt = creationTime;
      }
      user.lastLoginAt = lastSignInTime;

      dispatch(createOrUpdateUser(user));
      setIsUserLoggedIn(true);
    }
  };

  const handleRedirectResult = () => {
    props.firebase.firebaseAuth
      .getRedirectResult()
      .then(function (result) {
        if (result.credential) {
          // This gives you a Google Access Token. You can use it to access the Google API.
          const token = result.credential.accessToken;
          // ...
        }
        // The signed-in user info.
        const user = result.user;
        if (user && !isUserLoggedIn) {
          makeUserLoggedIn(user);
        }
      })
      .catch(function (error) {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        const credential = error.credential;
        // ...
      });
  };

  const handleFirebaseAuth = () => {
    if (user !== null) {
      return;
    }
    props.firebase.firebaseAuth.onAuthStateChanged((user) => {
      if (user && !isUserLoggedIn) {
        makeUserLoggedIn(user);
      } else {
        handleRedirectResult();
      }
    });
  };

  const handleLogout = () => {
    props.firebase.logout().then(() => {
      console.log('Logging out');

      setIsUserLoggedIn(false);
      setAnchorEl(null);

      dispatch(logoutUser());
      props.history.push('/home');
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggleMenu = (e) => {
    e.preventDefault();
    setIsExpanded(!isExpanded);
  };

  const renderUserAvatar = () => {
    if (isUserLoading && !user) {
      return <CircularProgress className="loader" size={24} />;
    } else if (user && !isUserLoading) {
      return (
        <Avatar
          alt={user.displayName}
          className="profile-icon"
          onClick={handleClick}
          src={user.photoURL}
        />
      );
    } else {
      return (
        <div>
          <span
            className="login-button"
            onClick={() => setLoginModalOpen(true)}
          >
            Login
          </span>
          <span
            className="sign-up-button"
            onClick={() => setSignUpModalOpen(true)}
          >
            Register
          </span>
        </div>
      );
    }
  };

  const getUserAreaMenu = (user, anchorEl) => {
    return (
      <div className="user-area">
        {renderUserAvatar()}

        <StyledMenu
          className="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {/*<StyledMenuItem>
              <ListItemIcon>
                <Settings fontSize="small"/>
              </ListItemIcon>
              <ListItemText primary="Settings"/>
            </StyledMenuItem>
            <StyledMenuItem>
              <ListItemIcon>
                <Subscriptions fontSize="small"/>
              </ListItemIcon>
              <ListItemText primary="Subscription"/>
            </StyledMenuItem>*/}
          <StyledMenuItem onClick={() => handleLogout()}>
            <ListItemIcon>
              <ExitToApp fontSize="small" className="logout-button" />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </StyledMenuItem>
        </StyledMenu>
      </div>
    );
  };

  const getMobileHeaderMenu = () => {
    let menu = [];
    let userRoleMenu;
    if (user) {
      userRoleMenu = getMenu(user.role);
    } else {
      userRoleMenu = getMenu('user');
    }

    return (
      <div
        role="presentation"
        className="menu-container"
        onClick={(event) => toggleDrawer(event, false)}
        onKeyDown={(event) => toggleDrawer(event, false)}
      >
        <div className="logo">
          <p className="navbar-brand" onClick={() => props.history.push('/')}>
            <img src={require('../assets/goodbod_logo.jpg')} alt="GoodBod" />
          </p>
        </div>
        <List>
          {userRoleMenu.map((userMenu, index) => (
            <ListItem button key={index}>
              <NavLink
                key={userMenu.link}
                className={`menu ${
                  activeMenuName === `${userMenu.link}` ? 'active-menu' : ''
                }`}
                to={userMenu.link}
                exact={true}
              >
                {userMenu.name}
              </NavLink>
            </ListItem>
          ))}
        </List>
        <Divider />
      </div>
    );
  };

  const toggleDrawer = (event, open) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const getHeaderMenu = (isExpanded, activeMenuName) => {
    let menu = [];
    let userRoleMenu;
    if (user) {
      userRoleMenu = getMenu(user.role);
    } else {
      userRoleMenu = getMenu('user');
    }
    userRoleMenu.forEach((userMenu) => {
      const { name, link, activeLinks } = userMenu;
      let isLinkActive = false;
      activeLinks.forEach((activeLink) => {
        if (activeMenuName.indexOf(activeLink) > -1) {
          isLinkActive = true;
        }
      });
      menu.push(
        <NavLink
          key={link}
          className={`menu ${isLinkActive ? 'active-menu' : ''}`}
          to={link}
          exact={true}
        >
          {name}
        </NavLink>
      );
    });
    return (
      <div
        onClick={handleToggleMenu}
        className={`menu-area collapsed ${isExpanded ? 'is-expanded' : ''}`}
      >
        {menu}
      </div>
    );
  };

  const getTopHeaderMenu = () => {
    let menu = [];
    TOP_HEADER_MENU.forEach((userMenu) => {
      const { name, link } = userMenu;
      menu.push(
        <NavLink key={link} className={'menu'} to={link} exact={true}>
          {name}
        </NavLink>
      );
    });
    return <div className={`menu-area collapsed`}>{menu}</div>;
  };

  const activeMenuName = props.location.pathname;
  let headerClassName = 'header-top-area';
  if (scrolled) {
    headerClassName += ' fixed-header';
  }

  return (
    <>
      <div className="top-header-container">{getTopHeaderMenu()}</div>
      <div className={headerClassName}>
        <MenuIcon
          className="collapsible-menu-icon"
          onClick={(e) => toggleDrawer(e, true)}
        />
        <div className="logo">
          <img
            src={logo}
            alt="GoodBod Logo"
            onClick={() => props.history.push('/home')}
            className="logo"
          />
        </div>
        <Drawer
          open={drawerOpen}
          onClose={(event) => toggleDrawer(event, false)}
          className="menu-drawer"
        >
          {getMobileHeaderMenu(isExpanded, activeMenuName)}
        </Drawer>
        {getHeaderMenu(isExpanded, activeMenuName)}
        {getUserAreaMenu(user, anchorEl)}
      </div>
      {renderLoginModal()}
      {renderSignUpModal()}
    </>
  );
};

export default withRouter(withFirebase(Header));

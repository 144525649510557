import {
  CLEAR_WORKOUT_MATERIAL_STATE,
  CLEAR_WORKOUT_STATE,
  CLEAR_WORKOUT_VIDEO_STATE,
  CREATE_WORKOUT_MATERIAL,
  CREATE_WORKOUT_VIDEO,
  DELETE_WORKOUT,
  DELETE_WORKOUT_MATERIAL,
  DELETE_WORKOUT_VIDEO,
  EXECUTION_STATUS,
  GET_WORKOUT_MATERIALS,
  GET_WORKOUT_OVERVIEW,
  GET_WORKOUT_VIDEO,
  UPDATE_WORKOUT,
  UPDATE_WORKOUT_MATERIAL,
  UPDATE_WORKOUT_OVERVIEW,
  UPDATE_WORKOUT_VIDEO,
} from '../actions/actionTypes';

const INITIAL_STATE = {
  id: '',
  workoutId: '',
  overview: {},
  videos: [],
  materials: [],
  isOverviewLoading: false,
  isOverviewUpdating: false,
  isOverviewUpdated: false,
  isVideoLoading: false,
  isVideoUpdating: false,
  isVideoDeleting: false,
  isVideoDeleted: false,
  isVideoUpdated: false,
  isVideoSaving: false,
  isVideoSaved: false,
  isMaterialsLoading: false,
  isMaterialUpdating: false,
  isMaterialUpdated: false,
  isMaterialDeleting: false,
  isMaterialDeleted: false,
  isMaterialSaving: false,
  isMaterialSaved: false,
};

export default function (state = INITIAL_STATE, action) {
  let { payload } = action;
  const { STARTED, FINISHED, ERROR } = EXECUTION_STATUS;
  switch (action.type) {
    ////////////////////////////////////////////////////////////////////////////////////////

    case GET_WORKOUT_OVERVIEW + STARTED:
      return {
        ...state,
        isOverviewLoading: true,
      };
    case GET_WORKOUT_OVERVIEW + FINISHED:
      return {
        ...state,
        overview: payload,
        isOverviewLoading: false,
      };
    case GET_WORKOUT_OVERVIEW + ERROR:
      return {
        ...state,
        isOverviewLoading: false,
        error: 'Error while loading workout overview',
      };

    ////////////////////////////////////////////////////////////////////////////////////////

    case UPDATE_WORKOUT_OVERVIEW + STARTED:
      return {
        ...state,
        isOverviewUpdating: true,
        isOverviewUpdated: false,
      };
    case UPDATE_WORKOUT_OVERVIEW + FINISHED:
      console.log('WORKOUTOVERVIEW PAYLOAD ', payload);
      return {
        ...state,
        overview: payload,
        isOverviewUpdating: false,
        isOverviewUpdated: true,
      };
    case UPDATE_WORKOUT_OVERVIEW + ERROR:
      console.log('IN CREATE_WORKOUT_ERROR :: ');
      return {
        ...state,
        isCreating: false,
        isOverviewUpdated: false,
        error: 'Error while creating workout',
      };

    ////////////////////////////////////////////////////////////////////////////////////////

    case CLEAR_WORKOUT_STATE + FINISHED:
      return {
        ...state,
        isCreating: false,
        isCreated: false,
        isLoading: false,
        isUpdated: false,
        isUpdating: false,
      };

    ////////////////////////////////////////////////////////////////////////////////////////

    case GET_WORKOUT_VIDEO + STARTED:
      return {
        ...state,
        isVideoLoading: true,
      };
    case GET_WORKOUT_VIDEO + FINISHED:
      return {
        ...state,
        videos: payload,
        isVideoLoading: false,
      };
    case GET_WORKOUT_VIDEO + ERROR:
      return {
        ...state,
        isVideoLoading: false,
        error: 'Error while loading workout videos',
      };

    ////////////////////////////////////////////////////////////////////////////////////////

    case CREATE_WORKOUT_VIDEO + STARTED:
      return {
        ...state,
        isVideoSaving: true,
        isVideoSaved: false,
      };
    case CREATE_WORKOUT_VIDEO + FINISHED:
      console.log('STATE VIDEOS :: ', state.videos);
      console.log('STATE VIDEOS length :: ', state.videos.length);
      state.videos.unshift(payload);
      return {
        ...state,
        isVideoSaving: false,
        isVideoSaved: true,
      };
    case CREATE_WORKOUT_VIDEO + ERROR:
      console.log('IN CREATE_WORKOUT_VIDEO_ERROR :: ');
      return {
        ...state,
        isVideoSaving: false,
        isVideoSaved: false,
        error: 'Error while creating workout video',
      };

    ////////////////////////////////////////////////////////////////////////////////////////

    case UPDATE_WORKOUT_VIDEO + STARTED:
      return {
        ...state,
        isVideoUpdating: true,
        isVideoUpdated: false,
      };
    case UPDATE_WORKOUT_VIDEO + FINISHED:
      state.videos.map((video) => {
        if (video.id === payload.id) {
          Object.assign(video, payload);
        }
        return video;
      });
      return {
        ...state,
        isVideoUpdating: false,
        isVideoUpdated: true,
      };
    case UPDATE_WORKOUT_VIDEO + ERROR:
      console.log('IN CREATE_WORKOUT_ERROR :: ');
      return {
        ...state,
        isVideoUpdating: false,
        isVideoUpdated: false,
        error: 'Error while updating workout video',
      };

    ////////////////////////////////////////////////////////////////////////////////////////

    case DELETE_WORKOUT_VIDEO + STARTED:
      return {
        ...state,
        isVideoDeleting: true,
        isVideoDeleted: false,
      };
    case DELETE_WORKOUT_VIDEO + FINISHED:
      console.log('VIDEO ID TO BE DELETED ::: ', payload);
      const videosAfterDelete = state.videos.filter(
        (video) => video.id !== payload
      );
      return {
        ...state,
        videos: videosAfterDelete,
        isVideoDeleting: false,
        isVideoDeleted: true,
      };
    case DELETE_WORKOUT_VIDEO + ERROR:
      console.log('IN DELETE_WORKOUT_VIDEO_ERROR :: ');
      return {
        ...state,
        isVideoDeleting: false,
        isVideoDeleted: false,
        error: 'Error while deleting workout video',
      };

    ////////////////////////////////////////////////////////////////////////////////////////

    case CLEAR_WORKOUT_VIDEO_STATE + FINISHED:
      return {
        ...state,
        isVideoSaving: false,
        isVideoSaved: false,
        isVideoLoading: false,
        isVideoUpdated: false,
        isVideoUpdating: false,
      };

    ////////////////////////////////////////////////////////////////////////////////////////

    case GET_WORKOUT_MATERIALS + STARTED:
      return {
        ...state,
        isMaterialsLoading: true,
      };
    case GET_WORKOUT_MATERIALS + FINISHED:
      return {
        ...state,
        materials: payload,
        isMaterialsLoading: false,
      };
    case GET_WORKOUT_MATERIALS + ERROR:
      return {
        ...state,
        isMaterialsLoading: false,
        error: 'Error while loading workout materials',
      };

    ////////////////////////////////////////////////////////////////////////////////////////

    case CREATE_WORKOUT_MATERIAL + STARTED:
      return {
        ...state,
        isMaterialSaving: true,
        isMaterialSaved: false,
      };
    case CREATE_WORKOUT_MATERIAL + FINISHED:
      state.materials.unshift(payload);
      /*if (state.materials.find(material => material.id === payload.id)) {
      
      }*/
      console.log(
        'IN CREATE_WORKOUT_MATERIAL FINISHED ::"::::::::::::::::::::::::::::?????????????????/**/'
      );
      return {
        ...state,
        isMaterialSaving: false,
        isMaterialSaved: true,
      };
    case CREATE_WORKOUT_MATERIAL + ERROR:
      console.log('IN CREATE_WORKOUT_MATERIAL :: ');
      return {
        ...state,
        isMaterialSaving: false,
        isMaterialSaved: false,
        error: 'Error while creating workout material',
      };

    ////////////////////////////////////////////////////////////////////////////////////////

    case UPDATE_WORKOUT_MATERIAL + STARTED:
      return {
        ...state,
        isMaterialUpdating: true,
        isMaterialUpdated: false,
      };
    case UPDATE_WORKOUT_MATERIAL + FINISHED:
      state.materials.map((material) => {
        if (material.id === payload.id) {
          Object.assign(material, payload);
        }
        return material;
      });
      return {
        ...state,
        isMaterialUpdating: false,
        isMaterialUpdated: true,
      };
    case UPDATE_WORKOUT_MATERIAL + ERROR:
      console.log('IN CREATE_WORKOUT_ERROR :: ');
      return {
        ...state,
        isMaterialUpdating: false,
        isMaterialUpdated: false,
        error: 'Error while updating workout video',
      };

    ////////////////////////////////////////////////////////////////////////////////////////

    case DELETE_WORKOUT_MATERIAL + STARTED:
      return {
        ...state,
        isMaterialDeleting: true,
        isMaterialDeleted: false,
      };
    case DELETE_WORKOUT_MATERIAL + FINISHED:
      console.log('VIDEO ID TO BE DELETED ::: ', payload);
      const materialsAfterDelete = state.materials.filter(
        (material) => material.id !== payload
      );
      return {
        ...state,
        materials: materialsAfterDelete,
        isMaterialDeleting: false,
        isMaterialDeleted: true,
      };
    case DELETE_WORKOUT_MATERIAL + ERROR:
      console.log('IN DELETE_WORKOUT_VIDEO_ERROR :: ');
      return {
        ...state,
        isMaterialDeleting: false,
        isMaterialDeleted: false,
        error: 'Error while deleting workout video',
      };

    ////////////////////////////////////////////////////////////////////////////////////////

    case CLEAR_WORKOUT_MATERIAL_STATE + FINISHED:
      return {
        ...state,
        isMaterialSaving: false,
        isMaterialSaved: false,
        isMaterialLoading: false,
        isMaterialUpdated: false,
        isMaterialUpdating: false,
      };
    default:
      return state;
  }
}

function updateWorkout(workout, index, workouts, updatedWorkout) {
  if (workout.id === updatedWorkout.id) {
    workout = updatedWorkout;
    workouts[index] = workout;
  }
  return workout;
}

import {
  CLEAR_WORKOUT_STATE,
  CREATE_DETAIL_DIET,
  DELETE_DETAIL_DIET,
  EXECUTION_STATUS,
  GET_DETAIL_DIET,
  UPDATE_WORKOUT,
  UPDATE_DETAIL_DIET,
  CLEAR_DETAIL_DIET_STATE,
} from '../actions/actionTypes';

const INITIAL_STATE = {
  id: '',
  dietId: '',
  dietContents: [],
  isLoading: false,
  isSaving: false,
  isUpdating: false,
  isUpdated: false,
  isDeleting: false,
};

export default function (state = INITIAL_STATE, action) {
  let { payload } = action;
  const { STARTED, FINISHED, ERROR } = EXECUTION_STATUS;
  switch (action.type) {
    ////////////////////////////////////////////////////////////////////////////////////////

    case GET_DETAIL_DIET + STARTED:
      return {
        ...state,
        isLoading: true,
      };
    case GET_DETAIL_DIET + FINISHED:
      return {
        ...state,
        dietContents: payload,
        isLoading: false,
      };
    case GET_DETAIL_DIET + ERROR:
      return {
        ...state,
        isLoading: false,
        error: 'Error while loading detail diet',
      };

    ////////////////////////////////////////////////////////////////////////////////////////

    case CREATE_DETAIL_DIET + STARTED:
      return {
        ...state,
        isSaving: true,
      };
    case CREATE_DETAIL_DIET + FINISHED:
      state.dietContents.unshift(payload);
      return {
        ...state,
        isSaving: false,
      };
    case CREATE_DETAIL_DIET + ERROR:
      return {
        ...state,
        isSaving: false,
        error: 'Error while loading detail diet',
      };

    ////////////////////////////////////////////////////////////////////////////////////////

    case UPDATE_DETAIL_DIET + STARTED:
      return {
        ...state,
        isUpdating: true,
        isUpdated: false,
      };
    case UPDATE_DETAIL_DIET + FINISHED:
      return {
        ...state,
        isUpdating: false,
        isSaving: false,
        isUpdated: true,
      };
    case UPDATE_DETAIL_DIET + ERROR:
      console.log('IN UPDATE_DETAIL_DIET_ERROR :: ');
      return {
        ...state,
        isUpdating: false,
        isUpdated: false,
        error: 'Error while updating detail diet',
      };

    ////////////////////////////////////////////////////////////////////////////////////////

    case DELETE_DETAIL_DIET + STARTED:
      return {
        ...state,
        isDeleting: true,
      };
    case DELETE_DETAIL_DIET + FINISHED:
      const detailDietToDelete = payload;
      const dietContentsAfterDelete = state.dietContents.filter(
        (diet) => diet.id !== detailDietToDelete
      );
      return {
        ...state,
        dietContents: dietContentsAfterDelete,
        isDeleting: false,
      };
    case DELETE_DETAIL_DIET + ERROR:
      return {
        ...state,
        isDeleting: true,
        error: 'Error while deleting workout',
      };

    ////////////////////////////////////////////////////////////////////////////////////////

    case CLEAR_DETAIL_DIET_STATE + FINISHED:
      return {
        ...state,
        isLoading: false,
        isSaving: false,
        isUpdating: false,
        isUpdated: false,
        isDeleting: false,
      };

    default:
      return state;
  }
}

function updateWorkout(workout, index, workouts, updatedWorkout) {
  if (workout.id === updatedWorkout.id) {
    workout = updatedWorkout;
    workouts[index] = workout;
  }
  return workout;
}

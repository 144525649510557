import {
  CLEAR_SUBSCRIPTION_STATE,
  CREATE_SUBSCRIPTION,
  EXECUTION_STATUS,
} from '../actions/actionTypes';

const INITIAL_STATE = {
  plans: [],
  isLoading: false,
  isSaving: false,
  successMessage: null,
  errorMessage: null,
};

export default function (state = INITIAL_STATE, action) {
  let { error } = action;
  const { STARTED, FINISHED, ERROR } = EXECUTION_STATUS;
  switch (action.type) {
    ////////////////////////////////////////////////////////////////////////////////////////

    case CREATE_SUBSCRIPTION + STARTED:
      return {
        ...state,
        isSaving: true,
      };
    case CREATE_SUBSCRIPTION + FINISHED:
      return {
        ...state,
        isSaving: false,
        successMessage:
          'Subscription registered successfully. One of our representative will contact you shortly.',
      };
    case CREATE_SUBSCRIPTION + ERROR:
      return {
        ...state,
        isSaving: false,
        errorMessage: error ? error : 'Error while saving subscription',
      };

    ////////////////////////////////////////////////////////////////////////////////////////

    case CLEAR_SUBSCRIPTION_STATE:
      return {
        ...state,
        isLoading: false,
        successMessage: null,
        errorMessage: null,
      };
    default:
      return state;
  }
}

import { combineReducers } from 'redux';
import userReducer from "./userReducer";
import workoutReducer from "./workoutReducer";
import detailWorkoutReducer from "./detailWorkoutReducer";
import plansReducer from "./plansReducer";
import subscriptionReducer from "./subscriptionReducer";
import dietReducer from "./dietReducer";
import detailDietReducer from "./detailDietReducer";
import blogReducer from "./blogReducer";

const rootReducer = combineReducers({
  user: userReducer,
  workout: workoutReducer,
  detailWorkout: detailWorkoutReducer,
  plans: plansReducer,
  subscription: subscriptionReducer,
  diet: dietReducer,
  detailDiet: detailDietReducer,
  blog: blogReducer
});

export default rootReducer;
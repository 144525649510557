import {
  CLEAR_WORKOUT_STATE,
  CREATE_WORKOUT,
  DELETE_WORKOUT,
  EXECUTION_STATUS,
  GET_WORKOUTS,
  UPDATE_WORKOUT,
} from '../actions/actionTypes';

const INITIAL_STATE = {
  workouts: [],
  id: '',
  name: '',
  time: '',
  duration: '',
  trainerName: '',
  description: '',
  workoutImage: null,
  isLoaded: false,
  isCreating: false,
  isCreated: false,
  isDeleting: false,
  isUpdating: false,
  isUpdated: false,
  isLoading: false,
};

export default function (state = INITIAL_STATE, action) {
  let { payload } = action;
  const { STARTED, FINISHED, ERROR } = EXECUTION_STATUS;
  switch (action.type) {
    ////////////////////////////////////////////////////////////////////////////////////////

    case GET_WORKOUTS + STARTED:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    case GET_WORKOUTS + FINISHED:
      return {
        ...state,
        workouts: JSON.parse(JSON.stringify(payload)),
        isLoading: false,
        isLoaded: true,
      };
    case GET_WORKOUTS + ERROR:
      console.log('IN GET_WORKOUTS_ERROR');
      return {
        ...state,
        isLoading: false,
        error: 'Error while loading workouts',
      };

    ////////////////////////////////////////////////////////////////////////////////////////

    case CREATE_WORKOUT + STARTED:
      return {
        ...state,
        isCreating: true,
        isCreated: false,
      };
    case CREATE_WORKOUT + FINISHED:
      state.workouts.unshift(payload);
      return {
        ...state,
        isCreating: false,
        isCreated: true,
      };
    case CREATE_WORKOUT + ERROR:
      console.log('IN CREATE_WORKOUT_ERROR :: ');
      return {
        ...state,
        isCreating: false,
        error: 'Error while creating workout',
      };

    ////////////////////////////////////////////////////////////////////////////////////////

    case UPDATE_WORKOUT + STARTED:
      return {
        ...state,
        isUpdating: true,
        isUpdated: false,
      };
    case UPDATE_WORKOUT + FINISHED:
      state.workouts.map((workout, index, workouts) =>
        updateWorkout(workout, index, workouts, payload)
      );
      return {
        ...state,
        isUpdating: false,
        isUpdated: true,
      };
    case UPDATE_WORKOUT + ERROR:
      console.log('IN UPDATE_WORKOUT_ERROR :::');
      return {
        ...state,
        isUpdating: false,
        error: 'Error while updating workout',
      };

    ////////////////////////////////////////////////////////////////////////////////////////

    case DELETE_WORKOUT + STARTED:
      return {
        ...state,
        isDeleting: true,
      };
    case DELETE_WORKOUT + FINISHED:
      const workoutToDelete = payload;
      const workoutsAfterDelete = state.workouts.filter(
        (workout) => workout.id !== workoutToDelete
      );
      return {
        ...state,
        workouts: workoutsAfterDelete,
        isDeleting: false,
      };
    case DELETE_WORKOUT + ERROR:
      return {
        ...state,
        isDeleting: true,
        error: 'Error while deleting workout',
      };

    ////////////////////////////////////////////////////////////////////////////////////////

    case CLEAR_WORKOUT_STATE + FINISHED:
      return {
        ...state,
        isCreating: false,
        isCreated: false,
        isLoading: false,
        isUpdated: false,
        isUpdating: false,
      };
    default:
      return state;
  }
}

function updateWorkout(workout, index, workouts, updatedWorkout) {
  if (workout.id === updatedWorkout.id) {
    workout = updatedWorkout;
    workouts[index] = workout;
  }
}

import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Redirect, Route, Router, Switch } from 'react-router';
import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import Firebase, { FirebaseContext } from './utils/Firebase';
import CircularProgress from '@material-ui/core/CircularProgress';
import Header from './components/Header';
import Footer from './components/Footer';
import './styles/layout.scss';

import './index.css';

const Login = lazy(() =>
  import(/* webpackChunkName: "Login" */ './screens/Login')
);

const Home = lazy(() =>
  import(/* webpackChunkName: "Home" */ './screens/Home')
);

const Workout = lazy(() =>
  import(/* webpackChunkName: "Workout" */ './screens/Workout/Workout')
);
const DetailWorkout = lazy(() =>
  import(
    /* webpackChunkName: "DetailWorkout" */ './screens/Workout/DetailWorkoutScreen'
  )
);
const AddWorkout = lazy(() =>
  import(/* webpackChunkName: "AddWorkout" */ './screens/Workout/AddWorkout')
);
const EditWorkoutOverviewScreen = lazy(() =>
  import(
    /* webpackChunkName: "EditWorkoutOverview" */ './screens/Workout/EditWorkoutOverviewScreen'
  )
);
const AddEditWorkoutVideoScreen = lazy(() =>
  import(
    /* webpackChunkName: "AddEditWorkoutVideo" */ './screens/Workout/AddEditWorkoutVidoeScreen'
  )
);
const AddEditWorkoutMaterialScreen = lazy(() =>
  import(
    /* webpackChunkName: "AddEditWorkoutMaterial" */ './screens/Workout/AddEditWorkoutMaterialScreen'
  )
);

const Plans = lazy(() =>
  import(/* webpackChunkName: "Plans" */ './screens/Plans/index')
);
const Payment = lazy(() =>
  import(/* webpackChunkName: "Payment" */ './screens/Plans/Payment')
);

const Diet = lazy(() =>
  import(/* webpackChunkName: "Diet" */ './screens/Diet/Diet')
);
const AddDiet = lazy(() =>
  import(/* webpackChunkName: "AddDiet" */ './screens/Diet/AddDiet')
);
const DetailDiet = lazy(() =>
  import(/* webpackChunkName: "DetailDiet" */ './screens/Diet/DetailDietScreen')
);
const AddEditDetailDietScreen = lazy(() =>
  import(
    /* webpackChunkName: "AddEditDetailDiet" */ './screens/Diet/AddEditDetailDietScreen'
  )
);

const Blog = lazy(() =>
  import(/* webpackChunkName: "Blog" */ './screens/Blog/Blog')
);
const AddBlog = lazy(() =>
  import(/* webpackChunkName: "AddBlog" */ './screens/Blog/AddBlog')
);
const DetailBlog = lazy(() =>
  import(/* webpackChunkName: "DetailBlog" */ './screens/Blog/DetailBlogScreen')
);
const UpdateDetailBlogData = lazy(() =>
  import(
    /* webpackChunkName: "UpdateDetailBlog" */ './screens/Blog/UpdateDetailBlogData'
  )
);

const Help = lazy(() =>
  import(/* webpackChunkName: "Help" */ './screens/Help')
);
const About = lazy(() =>
  import(/* webpackChunkName: "About" */ './screens/About')
);
const Business = lazy(() =>
  import(/* webpackChunkName: "Business" */ './screens/Business')
);
const TrainerJoin = lazy(() =>
  import(/* webpackChunkName: "Trainer" */ './screens/TrainerJoin')
);
const Admin = lazy(() =>
  import(/* webpackChunkName: "Admin" */ './screens/Admin/index')
);
const NotFound = lazy(() =>
  import(/* webpackChunkName: "NotFound" */ './screens/NotFound')
);

const store = createStore(rootReducer, compose(applyMiddleware(thunk)));

export const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#ffc43e',
    },
  },
  appBar: {
    color: '#37517E',
    height: 50,
  },
});

const customHistory = createBrowserHistory();

const Root = () => {
  /*   window.addEventListener(
    'touchstart',
    function (e) {
      console.log(e.defaultPrevented); // will be false
      e.preventDefault(); // does nothing since the listener is passive
      console.log(e.defaultPrevented); // still false
    },
    { passive: true }
  ); */

  return (
    <Provider store={store}>
      <MuiThemeProvider theme={muiTheme}>
        <Suspense
          fallback={<CircularProgress className="loader" color="primary" />}
        >
          <Router history={customHistory}>
            <div className="layout-container">
              <Header />
              <div className="content-wrap">
                <Switch>
                  <Route exact path="/login" component={Login} />

                  <Route exact path="/home" component={Home} />

                  <Route exact path="/workout" component={Workout} />
                  <Route exact path="/add-workout" component={AddWorkout} />
                  <Route
                    exact
                    path="/add-workout/:workoutId"
                    component={AddWorkout}
                  />
                  <Route
                    exact
                    path="/detail-workout/:workoutId"
                    component={DetailWorkout}
                  />
                  <Route
                    exact
                    path="/edit-workout-overview/:workoutId"
                    component={EditWorkoutOverviewScreen}
                  />
                  <Route
                    exact
                    path="/add-workout-video/:workoutId"
                    component={AddEditWorkoutVideoScreen}
                  />
                  <Route
                    exact
                    path="/add-workout-video/:workoutId/:videoId"
                    component={AddEditWorkoutVideoScreen}
                  />
                  <Route
                    exact
                    path="/add-workout-material/:workoutId"
                    component={AddEditWorkoutMaterialScreen}
                  />
                  <Route
                    exact
                    path="/add-workout-material/:workoutId/:materialId"
                    component={AddEditWorkoutMaterialScreen}
                  />

                  <Route exact path="/plans" component={Plans} />
                  <Route exact path="/payment/:planId" component={Payment} />

                  <Route exact path="/diet" component={Diet} />
                  <Route exact path="/add-diet" component={AddDiet} />
                  <Route exact path="/add-diet/:dietId" component={AddDiet} />
                  <Route
                    exact
                    path="/detail-diet/:dietId"
                    component={DetailDiet}
                  />
                  <Route
                    exact
                    path="/add-detail-diet/:dietId"
                    component={AddEditDetailDietScreen}
                  />
                  <Route
                    exact
                    path="/add-detail-diet/:dietId/:detailDietId"
                    component={AddEditDetailDietScreen}
                  />

                  <Route exact path="/blog" component={Blog} />
                  <Route exact path="/add-blog" component={AddBlog} />
                  <Route exact path="/add-blog/:blogId" component={AddBlog} />
                  <Route
                    exact
                    path="/detail-blog/:blogId"
                    component={DetailBlog}
                  />
                  <Route
                    exact
                    path="/update-blog-data/:blogId"
                    component={UpdateDetailBlogData}
                  />

                  <Route exact path="/help" component={Help} />

                  <Route exact path="/about" component={About} />

                  <Route exact path="/business" component={Business} />

                  <Route exact path="/trainer-join" component={TrainerJoin} />

                  <Route exact path="/admin" component={Admin} />

                  <Redirect from="/" to="/home" />

                  <Route component={NotFound} />
                </Switch>
              </div>
              <Footer />
            </div>
          </Router>
        </Suspense>
      </MuiThemeProvider>
    </Provider>
  );
};

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <Root />
  </FirebaseContext.Provider>,
  document.getElementById('root')
);

registerServiceWorker();

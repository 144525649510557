import app from 'firebase/app';
import 'firebase/auth';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.firebaseAuth = app.auth();
    this.firebaseAuth.setPersistence(app.auth.Auth.Persistence.LOCAL);
  }

  loginWithGoogle = () => {
    return this.firebaseAuth.signInWithRedirect(this.googleProvider);
  };

  logout = () => {
    return this.firebaseAuth.signOut();
  };
}

export default Firebase;
